import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import * as ColorUtils from '../components/color-utils'


// eslint-disable-next-line
export const ContactPageTemplate = class extends React.Component {

	componentDidMount() {
		ColorUtils.setDarkColor();
	}

	render() {
		return (
      <section className='container-xxl container-padding'>
        <div className="row pb-5 pt-6">
          <div className="col-md-6">
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />

              <div className='row'>
                <div className='col'>
                  <label className='form-label-fest' htmlFor="firstName">First name *</label>
                  <input
                    className="form-control form-control-lg form-control-fest"
                    name="firstName"
                    id="firstName"
                    type="text"
                    required={true}
                  />
                </div>
                <div className='col'>
                  <label className='form-label-fest' htmlFor="lastName">Last name *</label>
                  <input
                    className="form-control form-control-lg form-control-fest"
                    name="lastName"
                    id="lastName"
                    type="text"
                    required={true}
                  />
                </div>
              </div>
              <label className='form-label-fest mt-3' htmlFor="email">Email *</label>
              <input
                className="form-control form-control-lg form-control-fest"
                name="email"
                type="email"
                id="email"
                required={true}
              />

              <label className='form-label-fest mt-3' htmlFor="message">Message *</label>
              <textarea
                className="form-control form-control-lg form-control-fest"
                name="message"
                rows="4"
                cols="50"
                id="message"
              ></textarea>

              <button className="btn btn-fest mt-3 mb-3" type="submit">
                Send
              </button>
            </form>
          </div>
          <div className="col-md-6">
            <div className='get-in-touch-fest'>
              <div>GET IN TOUCH</div>
              <div>GET IN TOUCH</div>
              <div>GET IN TOUCH</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ContactPageTemplate.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

const ContactPage = ({data}) => {
	// const { data2 } = data.markdownRemark.frontmatter;
	// console.log('hello data', data);
	// const data2 = data.markdownRemark.frontmatter;
	return (
		<Layout pageTitle='Fest • Contact'>
			<ContactPageTemplate />
		</Layout>
	)
};

ContactPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default ContactPage
